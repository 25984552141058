import * as React from "react"
import { Link, HeadFC } from "gatsby"
let sectionStyle = {
    backgroundColor: "hsl(218, 41%, 15%)",
    backgroundImage: 'radial-gradient(650px circle at 0% 0%,hsl(218, 41%, 35%) 15%,hsl(218, 41%, 30%) 35%,hsl(218, 41%, 10%) 75%,hsl(218, 41%, 0%) 80%,hsl(218, 41%, 0%) 100%,transparent 0%)',
};

import Image from "../images/Screenshot 2022-11-09 011701.png"

const NotFoundPage = () => {
    return (
        <main>
            <section className="mb-32 text-gray-800 dark:text-neutral-200" style={sectionStyle}>

                <div className="px-6 py-12 md:px-12 text-center lg:text-left">
                    <div className="container mx-auto">
                        <div className="grid lg:grid-cols-2 gap-12 flex items-center">
                            <div className="mt-12 lg:mt-0">
                                <h1 className="text-5xl md:text-6xl xl:text-7xl font-bold tracking-tight mb-12"
                                    style={{ color: "hsl(218, 81%, 95%)" }}>
                                    Summarize is in<br /><span style={{ color: "hsl(218, 81%, 75%)" }}>heavy development</span>
                                </h1>
                                <p className="text-lg" style={{ color: "hsl(218, 81%, 95%)" }}>
                                    Please Note that all the UI is in testing phase and can change, what will not change is the imapact, and its abilites
                                    below are some of the samples which summarize's models generate
                                </p>
                            </div>
                            <div className="mb-12 lg:mb-0">
                                <div className="embed-responsive embed-responsive-16by9 relative w-full overflow-hidden rounded-lg shadow-lg"
                                    style={{ paddingTop: "16.25%" }}>
                                    <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
                                        <iframe src="https://player.vimeo.com/video/770196114?h=b4ec9cdc3f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameBorder={0} allow="autoplay; fullscreen; picture-in-picture" allowFullScreen style={{position:"absolute",top:0,left:0,width:"100%",height:"100%",}} title="Summarize Live Writing Demo"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="flex justify-around font-dmsans dark:text-neutral-200">
                    <div className="flex">
                        <div className="bg-slate-100 dark:bg-neutral-800 p-2 rounded-lg max-w-lg mx-2 flex-shrink">
                            <p className="text-lg dark:text-neutral-100 font-bold py-4" >
                                Summary of <a href="https://en.wikipedia.org/wiki/Google#">Google Wiki (Top Section)</a> Generated in about <span className="text-xl text-cyan-600"> 0.6 sec</span>
                            </p>
                            <p>
                                Google is one of the world's most valuable brands due to its market dominance, data collection, and technological advantages in the area of artificial intelligence. Google was founded on September 4, 1998, by Larry Page and Sergey Brin while they were PhD students at Stanford University in California. Google is also the largest search engine, mapping ...
                            </p>
                        </div>
                        <div className="bg-slate-100 dark:bg-neutral-800 p-2 rounded-lg max-w-4xl mx-2">
                            <p className="text-lg dark:text-neutral-100 font-bold py-4" >
                                Paragraphs Genrated from "One day I ..." <span className="text-xl text-cyan-600"> 0.2 sec</span>
                            </p>
                            <p>
                                "One day I was sitting at my desk, typing away on my laptop, and I started thinking about all the things I wanted to do when I grew up. One of those things would be to go out with my best friend's family for a weekend.<br /><br />My best friend is one of three people in my family. I grew up with her mom, dad, and sister. She's been with me since I was six years old, and we've known each other ever longer than we've known our parents. When she was younger, she used to come over every once in a while just to hang out after school, but that's about the only time they ever saw us together again.<br /><br />When she was younger, there were times when it seemed like we were never going to see each others' faces or see each other anymore. It was hard not to feel bad for her sometimes.<br /><br />But now that I'm older…<br /><br />I don't know if it's because everyone's grown up so much—or if it's just that I've grown up so fast; I have no idea how long I'll be able to keep growing up.<br /><br />And I don't want to grow up any faster than I already am right now! I want to stop growing up.<br /><br />So this weekend will be my first time ever going out without my best friends, and I can tell you from personal experience that it's definitely going to suck being alone with your best"
                            </p>
                        </div>
                    </div>
                    <div className="">
                        <div className="flex justify-center items-center">
                            <div className="relative w-[307px] h-[136px] bg-gradient-to-bl from-black/100 to-black/50 rounded-xl">
                                <div className="flex justify-center items-center pt-6">
                                    <div className="mx-2">
                                        <div className="flex justify-center items-center w-[55px] h-[55px] bg-gradient-to-bl from-black/100 to-black/20 rounded-xl">
                                            <p className="text-[36px] font-bold text-white">
                                                9
                                            </p>
                                        </div>
                                        <p className="my-1 text-xs font-bold text-center text-white">
                                            DAYS
                                        </p>
                                    </div>
                                    <div className="mx-2">
                                        <div className="flex justify-center items-center w-[55px] h-[55px] bg-gradient-to-bl from-black/100 to-black/20 rounded-xl">
                                            <p className="text-[36px] font-bold text-white">
                                                8
                                            </p>
                                        </div>
                                        <p className="my-1 text-xs font-bold text-center text-white">
                                            HOURS
                                        </p>
                                    </div>
                                    <div className="mx-2">
                                        <div className="flex justify-center items-center w-[55px] h-[55px] bg-gradient-to-bl from-black/100 to-black/20 rounded-xl">
                                            <p className="text-[36px] font-bold text-white">
                                                12
                                            </p>
                                        </div>
                                        <p className="my-1 text-xs font-bold text-center text-white">
                                            MINUTES
                                        </p>
                                    </div>
                                </div>
                                <p className="text-xs font-bold text-center text-[#969696]">
                                    From start of summarize desktop development
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default NotFoundPage

export const Head: HeadFC = () => <title>Demo Page</title>
